import React from 'react';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const visiblePages = [];

  // Calculate the range of visible pages
  let startPage = Math.max(1, currentPage - 2);
  let endPage = Math.min(totalPages, startPage + 4);

  // Push visible pages
  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  // Add ellipsis at the beginning if necessary
  if (startPage > 1) {
    visiblePages.unshift('...');
  }

  // Add ellipsis at the end if necessary
  if (endPage < totalPages) {
    visiblePages.push('...');
  }

  return (
    <div className="pagination-container">
      {currentPage > 1 && (
        <button className="pagination__button" onClick={() => onPageChange(currentPage - 1)}>
          &lt;&lt;
        </button>
      )}

      <div className="pagination">
        {visiblePages.map((page, index) => (
          <span
            key={index}
            className={`pagination__item ${page === currentPage ? 'active' : ''}`}
            onClick={() => page !== '...' && onPageChange(page)}
          >
            {page}
          </span>
        ))}
      </div>

      {currentPage < totalPages && (
        <button className="pagination__button" onClick={() => onPageChange(currentPage + 1)}>
          &gt;&gt;
        </button>
      )}
    </div>
  );
};

export default CustomPagination;
