import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const UserEdit = () => {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    taxCode: "",
    vatNumber: "",
    generic1: "",
    generic2: "",
  });
  const [errors, setErrors] = useState({});
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response?.data);
        setFormData({
          firstName: response?.data?.user?.firstName,
          lastName: response?.data?.user?.lastName,
          email: response?.data?.user?.email,
          address: response?.data?.user?.address,
          taxCode: response?.data?.user?.taxCode,
          vatNumber: response?.data?.user?.vatNumber,
          generic1: response?.data?.user?.generic1,
          generic2: response?.data?.user?.generic2,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
    }
    if (!formData.taxCode.trim()) {
      newErrors.taxCode = "Tax Code is required";
    }
    // if (!formData.vatNumber) {
    //   newErrors.vatNumber = "Vat Number is required";
    // }
    if (!formData.generic1.trim()) {
      newErrors.generic1 = "Generic1 is required";
    }
    if (!formData.generic2.trim()) {
      newErrors.generic2 = "Generic2 is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/update-profile`, {
        id: userId,
        ...formData,
      });
      toast.success("User details updated successfully!");
      navigate("/users");
    } catch (error) {
      console.error("Error updating user details:", error);
      alert("Failed to update user details.");
    }
  };

  console.log("user", user);
  console.log("formData", formData);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {user && user?.user && (
                        <>
                          <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                              type="text"
                              name="firstName"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              placeholder="Enter firstName"
                              value={formData?.firstName}
                              onChange={handleInputChange}
                            />
                            {errors.firstName && (
                              <div className="invalid-feedback">
                                {errors.firstName}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                              type="text"
                              name="lastName"
                              className={`form-control ${
                                errors.lastName ? "is-invalid" : ""
                              }`}
                              placeholder="Enter lastName"
                              value={formData?.lastName}
                              onChange={handleInputChange}
                            />
                            {errors.lastName && (
                              <div className="invalid-feedback">
                                {errors.lastName}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter email"
                              value={formData?.email}
                              onChange={handleInputChange}
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input
                              type="text"
                              name="address"
                              className={`form-control ${
                                errors.address ? "is-invalid" : ""
                              }`}
                              placeholder="Enter address"
                              value={formData?.address}
                              onChange={handleInputChange}
                            />
                            {errors.address && (
                              <div className="invalid-feedback">
                                {errors.address}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="taxCode">Tax Code</label>
                            <input
                              type="text"
                              name="taxCode"
                              className={`form-control ${
                                errors.taxCode ? "is-invalid" : ""
                              }`}
                              placeholder="Enter taxCode"
                              value={formData?.taxCode}
                              onChange={handleInputChange}
                            />
                            {errors.taxCode && (
                              <div className="invalid-feedback">
                                {errors.taxCode}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="vatNumber">Vat Number</label>
                            <input
                              type="number"
                              name="vatNumber"
                              className={`form-control ${
                                errors.vatNumber ? "is-invalid" : ""
                              }`}
                              placeholder="Enter vatNumber"
                              value={formData?.vatNumber}
                              onChange={handleInputChange}
                              readOnly
                            />
                            {errors.vatNumber && (
                              <div className="invalid-feedback">
                                {errors.vatNumber}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="generic1">Generic1</label>
                            <input
                              type="text"
                              name="generic1"
                              className={`form-control ${
                                errors.generic1 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter generic1"
                              value={formData?.generic1}
                              onChange={handleInputChange}
                            />
                            {errors.generic1 && (
                              <div className="invalid-feedback">
                                {errors.generic1}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="generic2">Generic2</label>
                            <input
                              type="text"
                              name="generic2"
                              className={`form-control ${
                                errors.generic2 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter generic2"
                              value={formData?.generic2}
                              onChange={handleInputChange}
                            />
                            {errors.generic2 && (
                              <div className="invalid-feedback">
                                {errors.generic2}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
