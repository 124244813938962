import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import AdminLayout from "./view/shared/AdminLayout";
import UnAuthLayout from "./view/shared/UnAuthLayout";
import PrivateRoute from "./view/shared/PrivateRoute";
import Dashboard from "./view/Admin/Dashboard";
import ViewAdmin from "./view/Admin/View";
import ChangePassword from "./view/Admin/ChangePassword";
import Login from "./view/Admin/Login";
import ResetPassword from "./view/Admin/ResetPassword";
import ForgotPassword from "./view/Admin/ForgotPassword";
import UnauthenticatedRoute from "./view/shared/UnauthenticatedRoute";
import User from "./view/User Management/User";
import Content from "./view/Cms Management/Content";
import Faq from "./view/Cms Management/Faq";
import Transaction from "./view/Transaction Management/Transaction";
import ContactUs from "./view/Contact Us/ContactUs";
import UserView from "./view/User Management/UserView";
import UserEdit from "./view/User Management/UserEdit";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      //admin routee
      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        path: "/dashboard",
        element: <PrivateRoute component={<Dashboard />} />,
      },
      {
        path: "/admin-view",
        element: <PrivateRoute component={<ViewAdmin />} />,
      },
      {
        path: "/admin-change-password",
        element: <PrivateRoute component={<ChangePassword />} />,
      },

      {
        path: "/logout",
        element: <PrivateRoute component={<Login />} />,
      },

      /// user management
      {
        path: "/users",
        element: <PrivateRoute component={<User />} />,
      },
      {
        path: "/users-view/:userId",
        element: <PrivateRoute component={<UserView />} />,
      },
      {
        path: "/users-edit/:userId",
        element: <PrivateRoute component={<UserEdit />} />,
      },

      /// transaction management
      {
        path: "/transactions",
        element: <PrivateRoute component={<Transaction />} />,
      },

      /// cms management
      {
        path: "/content-management",
        element: <PrivateRoute component={<Content />} />,
      },
      {
        path: "/faq",
        element: <PrivateRoute component={<Faq />} />,
      },

      /// contact us management
      {
        path: "/contact-us",
        element: <PrivateRoute component={<ContactUs />} />,
      },
    ],
  },
  {
    path: "/",
    element: <UnAuthLayout />,
    children: [
      {
        path: "/login",
        element: <UnauthenticatedRoute component={Login} />,
      },
      {
        path: "/reset-password",
        element: <UnauthenticatedRoute component={ResetPassword} />,
      },
      {
        path: "/forget-password",
        element: <UnauthenticatedRoute component={ForgotPassword} />,
      },
    ],
  },
]);

export default routes;
