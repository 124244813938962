import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const UserView = () => {
  const [user, setUser] = useState({});
  const { userId } = useParams();

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/view`,
        {
          params: { id: userId },
        }
      );
      setUser(response?.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  console.log("user : ", user);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    {user && user?.user && (
                      <>
                        <div className="user-info">
                          <strong>First Name:</strong> {user?.user?.firstName}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Last Name:</strong> {user?.user?.lastName}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Email:</strong> {user?.user?.email}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Address:</strong> {user?.user?.address}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Tax Code:</strong> {user?.user?.taxCode}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Vat Number:</strong> {user?.user?.vatNumber}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Generic1:</strong> {user?.user?.generic1}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Generic2:</strong> {user?.user?.generic2}
                        </div>
                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
